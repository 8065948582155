// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-design-tooling-js": () => import("./../../../src/pages/design-tooling.js" /* webpackChunkName: "component---src-pages-design-tooling-js" */),
  "component---src-pages-destiny-medals-js": () => import("./../../../src/pages/destiny-medals.js" /* webpackChunkName: "component---src-pages-destiny-medals-js" */),
  "component---src-pages-discord-growth-js": () => import("./../../../src/pages/discord-growth.js" /* webpackChunkName: "component---src-pages-discord-growth-js" */),
  "component---src-pages-healtcare-video-js": () => import("./../../../src/pages/healtcare-video.js" /* webpackChunkName: "component---src-pages-healtcare-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-steelseries-js": () => import("./../../../src/pages/steelseries.js" /* webpackChunkName: "component---src-pages-steelseries-js" */)
}

